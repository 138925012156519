import React from "react";

export default function Button({value = null, onClick, disabled = false}) {
    return (
        <div className={'col-3'}>
            {value !== null ? (
                <button className={'btn btn-primary'}
                        onClick={() => onClick(value)}
                        style={{margin: '2px', width: '100%'}}
                        disabled={disabled}>
                    {value}
                </button>
            ) : (
                <button className={'btn btn-primary'}
                        style={{margin: '2px', width: '100%'}}
                        disabled={false}>&nbsp;</button>
            )}
        </div>
    );
}
