import {Component} from 'react';
import Button from './components/Button';

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {total: 0, value: 0, done: null, symbol: null};

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(clicked) {

    const value = this.state.value;
    const total = this.state.total;

    this.setState({done: null});

    if (clicked === 'C') {
      //Reset
      this.setState({total: 0, value: 0, done: null, symbol: null});
    } else if (clicked === '=') {

      //Equals
      if (this.state.symbol === '/') {
        this.setState({
          total:  0,
          symbol: null,
          value:  0,
          done:   total / value,
        });
      } else if (this.state.symbol === '*') {
        this.setState({
          total:  0,
          symbol: null,
          value:  0,
          done:   total * value,
        });
      } else if (this.state.symbol === '-') {
        this.setState({
          total:  0,
          symbol: null,
          value:  0,
          done:   total - value,
        });
      } else if (this.state.symbol === '+') {
        this.setState({
          total:  0,
          symbol: null,
          value:  0,
          done:   total + value,
        });
      }


    } else if (clicked === '/') {
      this.setState({
        total:  total + value,
        symbol: '/',
        value:  0,
      });
    } else if (clicked === '*') {
      this.setState({
        total:  total + value,
        symbol: '*',
        value:  0,
      });
    } else if (clicked === '-') {
      this.setState({
        total:  total + value,
        symbol: '-',
        value:  0,
      });
    } else if (clicked === '+') {
      this.setState({
        total:  total + value,
        symbol: '+',
        value:  0,
      });
    } else if (clicked === 0 ||
               clicked === 1 ||
               clicked === 2 ||
               clicked === 3 ||
               clicked === 4 ||
               clicked === 5 ||
               clicked === 6 ||
               clicked === 7 ||
               clicked === 8 ||
               clicked === 9) {

      if (value === 0) {
        this.setState({value: clicked});
      } else {
        const c = parseInt(value.toString() + clicked.toString());
        this.setState({value: c});
      }
    }
  }

  render() {
    return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12 col-md-4 offset-md-4 mt-5'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <h1>Calculator ReactJS</h1>
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <div className="mb-3">
                  <input type="text"
                         className="form-control"
                         style={{textAlign: 'right'}}
                         disabled={true}
                         value={this.state.done !== null ? this.state.done : this.state.value}
                         placeholder="0"/>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <Button value={'C'} onClick={this.handleOnClick}/>
              <Button disabled={true}/>
              <Button disabled={true}/>
              <Button value={'/'} onClick={this.handleOnClick} disabled={this.state.symbol !== null}/>

              <Button value={7} onClick={this.handleOnClick}/>
              <Button value={8} onClick={this.handleOnClick}/>
              <Button value={9} onClick={this.handleOnClick}/>
              <Button value={'*'} onClick={this.handleOnClick} disabled={this.state.symbol !== null}/>

              <Button value={4} onClick={this.handleOnClick}/>
              <Button value={5} onClick={this.handleOnClick}/>
              <Button value={6} onClick={this.handleOnClick}/>
              <Button value={'-'} onClick={this.handleOnClick} disabled={this.state.symbol !== null}/>

              <Button value={1} onClick={this.handleOnClick}/>
              <Button value={2} onClick={this.handleOnClick}/>
              <Button value={3} onClick={this.handleOnClick}/>
              <Button value={'+'} onClick={this.handleOnClick} disabled={this.state.symbol !== null}/>

              <Button value={0} onClick={this.handleOnClick}/>
              <Button disabled={true}/>
              <Button disabled={true}/>
              <Button value={'='} onClick={this.handleOnClick}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
